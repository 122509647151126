import { FileRejection } from "react-dropzone";
import { FormElementV2ResponseDto } from 'src/backend';
import { KnowledgeBase, Type } from "src/constants/form-element";
import { DEFAULT_TOAST_DURATION, MAX_TOAST_DURATION, POLLING_INTERVAL } from "src/constants/ui";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import type { FormElement } from "src/types/view";

import { countWords } from "./count-words";
import { getFileExtension } from './get-file-extension';
import { isAFile } from './is-a-file';
import { toast } from "./toast";

export const isDevelopmentMode = process.env.NODE_ENV === 'development';

export const isServer = typeof window === 'undefined'

// is current domain localhost
export const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost';


// recursively find nested form element section by id
export const findSectionById = (id: string, rootSection: FormElement, allowGetFile: boolean = false, disallowShoeBox: boolean = false): FormElement | undefined => {
    if (!id || !rootSection || !rootSection.children) return rootSection;

    for (const section of rootSection.children) {
        if (section.id === id && (section.storageType === Type.SECTION || allowGetFile)) {
            if (section.knowledgeBase === KnowledgeBase.SHOE_BOX && disallowShoeBox) {
                return rootSection;
            }
            return section
        }
        if (section.storageType === Type.SECTION) {
            const nestedSection = findSectionById(id, section, allowGetFile);
            if (nestedSection && nestedSection.id === id || nestedSection.children.findIndex(file => file.id === id && file.storageType === Type.FILE) !== -1) {
                if (nestedSection.knowledgeBase === KnowledgeBase.SHOE_BOX && disallowShoeBox) {
                    return section;
                }
                return nestedSection
            }
        }
    }
    return rootSection
}
// find a specific form element by id and return it with all it's parents as a flatt array
export const findSectionByIdAndItsAncestors = (id: string, formElements: FormElementV2ResponseDtoExtended[], ignoreCurrent: boolean = false): FormElementV2ResponseDtoExtended[] => {
    const formElement = formElements.find(formElement => formElement.id === id);
    if (!formElement) return [];
    if (!formElement.parentId) return [];
    // if form element is file only return parents
    // if ignoreCurrent is true, ignore the current form element
    if (formElement.storageType === Type.FILE || ignoreCurrent) {
        return findSectionByIdAndItsAncestors(formElement.parentId, formElements)
    }
    return [formElement, ...findSectionByIdAndItsAncestors(formElement.parentId, formElements)]
}

export const findAllChildrenRecursively = (formElement: FormElementV2ResponseDto, formElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto[] => {
    const children = formElements.filter(child => child.parentId === formElement.id);
    if (!children.length) return [];
    return [...children, ...children.flatMap(child => findAllChildrenRecursively(child, formElements))]
}

const INVALID_FILE_MESSAGE = 'Invalid file type.  Please upload a valid file type.'

// Check dropped files are not folders
export const filterInvalidFiles = async (files: File[], rejectedFiles: FileRejection[]): Promise<{ acceptedFiles: File[], rejectedFiles: FileRejection[] }> => {
    const finalAcceptedFiles: File[] = [];
    const finalRejectedFiles: FileRejection[] = rejectedFiles
        .filter(rejectedFile => !['heic'].includes(getFileExtension(rejectedFile.file)))
        .map(rejectedFile => ({
            ...rejectedFile,
            errors: [
                {
                    message: INVALID_FILE_MESSAGE,
                    code: 'file-invalid-type'
                }
            ]
        }));
    for (const rejectedFile of rejectedFiles) {
        if (['heic'].includes(getFileExtension(rejectedFile.file))) {
            const { file } = rejectedFile;
            finalAcceptedFiles.push(file);
        }
    }
    for (const file of files) {
        const isValidFile = await isAFile(file);
        if (isValidFile && file.size > 0) {
            finalAcceptedFiles.push(file);
        } else if (!file || file.size === 0) {
            console.error('errored file uploaded', file.name, file.size)
            finalRejectedFiles.push({
                file,
                errors: [{
                    message: 'File is empty or folder has not be unzipped.  Please check file or unzip folder prior to uploading file',
                    code: 'file-too-small'
                }]
            });
        } else {
            finalRejectedFiles.push({
                file,
                errors: [
                    {
                        message: INVALID_FILE_MESSAGE,
                        code: 'file-invalid-type'
                    }
                ]
            });
        }
    }

    return ({
        acceptedFiles: finalAcceptedFiles,
        rejectedFiles: finalRejectedFiles
    });
}

// show toast for rejected dropped files
export const showRejectedDroppedFilesToast = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles?.length > 0) {
        const message = rejectedFiles.map(file => file.errors.map(error => error.message).join(', ')).join(', ');
        toast({
            content: message,
            type: 'error',
            duration: getToastDuration(message)
        });
    }
}

export const getErrors = (errors: any) => {
    if (!errors) {
        return {};
    }
    let errorObj = {};
    errors.forEach(error => {
        errorObj[error.field] = error.defaultMessage;
    });
    return errorObj;
}

const getTextReadDurationPerMillisecond = (text: string) => {
    return Math.ceil(countWords(text) / 3.9) * 1000;
}

export const getToastDuration = (text: string): number => {
    const calculatedDuration = getTextReadDurationPerMillisecond(text) * 1.5;
    let duration = DEFAULT_TOAST_DURATION;

    if (calculatedDuration > MAX_TOAST_DURATION) {
        duration = MAX_TOAST_DURATION;
    } else if (calculatedDuration > DEFAULT_TOAST_DURATION) {
        duration = calculatedDuration;
    }

    return duration;
}

class IntervalManager {
    private intervals: { [name: string]: ReturnType<typeof setInterval> } = {};

    public set(name: string, func: () => void, delayExecution: boolean = false, customPollInterval = 0): void {
        this.clear(name);
        if (!delayExecution) {
            func();
        }
        this.intervals[name] = setInterval(func, customPollInterval > 0 ? customPollInterval : POLLING_INTERVAL);
    }

    public clear(name: string): void {
        if (this.intervals[name]) {
            clearInterval(this.intervals[name]);
            delete this.intervals[name];
        }
    }

    public clearAll(): void {
        Object.keys(this.intervals).forEach(name => this.clear(name));
    }
}

// return file name without extension
export const getFileNameWithoutExtension = (fileName: string): string => {
    return String(fileName).split('.').slice(0, -1).join('.');
}


// create a singleton instance of IntervalManager
export const intervalManager = new IntervalManager();

// create url slug from string
export const createUrlSlug = (str: string): string => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return str.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}