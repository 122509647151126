
import { BusinessRequestDto, EntityLabelDto, LoanDto, RealEstateRequestDto } from 'src/backend';

import { baseApi } from './baseApi';

export const entityApi = baseApi.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
    endpoints: (build) => ({
        addLabelsToEntity: build.mutation<LoanDto, EntityLabelDto>({
            query: (data) => ({
                url: "/v2/loanmanager/entityLabel",
                method: 'POST',
                data
            }),
        }),
        postUpSomeRealEstateInformation: build.mutation<LoanDto, { entityId: string, information: RealEstateRequestDto }>({
            query: (data) => ({
                url: `/v2/information/realEstate/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),
        postUpSomeBusinessEntityInformation: build.mutation<LoanDto, { entityId: string, information: BusinessRequestDto }>({
            query: (data) => ({
                url: `/v2/information/business/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),

    }),
    overrideExisting: true,
})

export const {
    useAddLabelsToEntityMutation,
    usePostUpSomeRealEstateInformationMutation,
    usePostUpSomeBusinessEntityInformationMutation
} = entityApi;



