import { NaicsDto, VentureEntityDTO, VentureExportDataChange, VentureExportDataChangeDTO, VentureRequiredDataDTO, VentureTestCredentialDTO, VentureTestResultDTO } from 'src/backend';

import { baseApi } from './baseApi';

export const venturesApi = baseApi.enhanceEndpoints({ addTagTypes: ['VentureTestResultDTO'] }).injectEndpoints({
    endpoints: (build) => ({
        getNaicsList: build.query<NaicsDto[], void>({
            query: () => ({
                url: `/v1/naics`,
                method: 'GET',
            }),
        }),
        venturesConnectionTest: build.mutation<VentureTestResultDTO, { companyId: string }>({
            query: (data) => ({
                url: `/v1/lenders/${data.companyId}/venturesTest`,
                method: 'GET',
            }),
        }),
        venturesConnectionTestCredentials: build.mutation<VentureTestResultDTO, VentureTestCredentialDTO>({
            query: (data) => ({
                url: `/v1/lenders/venturesTestCredentials`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['VentureTestResultDTO'],
        }),
        exportLoanToVentures: build.mutation<VentureEntityDTO[], { loanId: string, dataChangeId: string }>({
            query: (data) => ({
                url: `/v1/venture/export/loan/${data.loanId}/${data.dataChangeId}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['VentureTestResultDTO'],
        }),
        // new
        checkVenturesRequiredData: build.query<VentureRequiredDataDTO, { loanId: string }>({
            query: (data) => ({
                url: `/v1/venture/checkrequireddata/${data.loanId}`,
                method: 'GET',
            }),
        }),
        checkVenturesDataChange: build.query<VentureExportDataChangeDTO, { loanId: string }>({
            query: (data) => ({
                url: `/v1/venture/checkdatachange/${data.loanId}`,
                method: 'GET',
            }),
        }),
        venturesDataChangeConfirmation: build.mutation<VentureExportDataChange, { loanId: string, payload: VentureExportDataChangeDTO }>({
            query: (data) => ({
                url: `/v1/venture/datachangeconfirm/${data.loanId}`,
                method: 'POST',
                data: data.payload,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useVenturesConnectionTestCredentialsMutation,
    useVenturesConnectionTestMutation,
    useExportLoanToVenturesMutation,
    // new
    useGetNaicsListQuery,
    useLazyCheckVenturesRequiredDataQuery,
    useLazyCheckVenturesDataChangeQuery,
    useVenturesDataChangeConfirmationMutation
} = venturesApi;



