import { FormElementV2ResponseDto, KnowledgeBase } from "src/backend";
import { ValueOf } from "src/types/common";

import { isFormElementAnIndividual } from "../is-form-element-an-individual";
import { isFormElementACompany } from "./is-form-element-a-company";
import { isFormElementATrust } from "./is-form-element-a-trust";
import { isFormElementAnAsset } from "./is-form-element-an-asset";

const FolderEntityType = {
    individual: 'INDIVIDUAL',
    asset: 'ASSET',
    project: 'PROJECT',
    business: 'BUSINESS',
    company: 'COMPANY',
    trust: 'TRUST',
    default: 'DEFAULT',
    empty: 'EMPTY'
} as const;

export const getFormElementEntityType = (formElement: FormElementV2ResponseDto): ValueOf<typeof FolderEntityType> => {

    if (formElement.knowledgeBase === KnowledgeBase.VIRTUAL_FOLDER && formElement.storageType === "FOLDER") {
        if (formElement.originalTitle === "Project Docs") {
            return FolderEntityType.project;
        } else if (formElement.originalTitle === "Company Docs") {
            return FolderEntityType.company;
        } else if (formElement.originalTitle === "Business Docs") {
            return FolderEntityType.business;
        } else if (formElement.originalTitle === "Business and Trust Docs") {
            return FolderEntityType.business;
        } else if (formElement.originalTitle === "Individual Docs") {
            return FolderEntityType.individual;
        } else if (formElement.originalTitle === "Trust Docs") {
            return FolderEntityType.trust;
        } else if (formElement.originalTitle === "Collateral Docs") {
            return FolderEntityType.asset;
        }
    }
    if (formElement.storageType === "FOLDER") {
        // if the form element is person use FolderIndividual, asset use FolderAsset, company use FolderCompany, trust use FolderTrust
        if (isFormElementAnIndividual(formElement)) {
            return FolderEntityType.individual;
        } else if (isFormElementAnAsset(formElement)) {
            return FolderEntityType.asset;
        } else if (isFormElementACompany(formElement)) {
            return FolderEntityType.company;
        } else if (isFormElementATrust(formElement)) {
            return FolderEntityType.trust;
        } else {
            return FolderEntityType.default;
        }
    }
}