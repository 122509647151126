import { AppUserDTO2, AppUserSignupRequestDto, BasicLoanDto, CompanyListDto, Document, EmployeeInfoDto, EmployeePublicDetailsDto, LoanPhaseDto, OnboardUserDto, PersonalShoeboxesDto, ShoeboxItemResponseDto, UniqueIdDto } from 'src/backend';
import { sortPhasesByDisplayOrder } from 'src/utils/loan/sort-phases-by-display-order';

import { baseApi } from './baseApi';

export const companyApi = baseApi.enhanceEndpoints({ addTagTypes: ["ShoeboxItemResponseDto", "LoanPhaseDto", 'EmployeePublicDetailsDto'] }).injectEndpoints({
    endpoints: (build) => ({
        isSubdomainAvailable: build.mutation<boolean, { subdomain: string, companyId?: string }>({
            query: (data) => ({
                url: data.companyId ? `/v1/lenders/isSubdomainUsed/${data.subdomain}/${data.companyId}` : `/v1/lenders/isSubdomainUsed/${data.subdomain}`,
                method: 'GET',
            }),
        }),
        addCompanyUser: build.mutation<AppUserDTO2, OnboardUserDto>({
            query: (data) => ({
                url: '/v1/lenders/companyUser',
                method: 'POST',
                data,
            }),
        }),
        getCompanyShoeBoxes: build.query<{ [index: string]: ShoeboxItemResponseDto[] }, { companyId: string }>({
            query: (data) => ({
                url: `/v2/shoebox/company/${data.companyId}`,
                method: 'GET',
            }),
            providesTags: ["ShoeboxItemResponseDto"],
            transformResponse: (response: PersonalShoeboxesDto) => {
                return response.shoeboxMap
            }
        }),
        updateCompanyUser: build.mutation<AppUserDTO2, { id: string, payload: Omit<AppUserSignupRequestDto, 'confirmationCode' | 'invitationId' | 'personId' | 'username' | 'url'> }>({
            query: (data) => ({
                url: `/v1/auth/editUser/${data.id}`,
                method: 'POST',
                data: data.payload,
            }),
        }),
        getCompanyEmployees: build.query<AppUserDTO2[], { companyId: string }>({
            query: (data) => ({
                url: `/v1/lenders/${data.companyId}/employees`,
                method: 'GET',
            }),
            transformResponse: (response: { employees: AppUserDTO2[] }) => {
                return response.employees
            }
        }),
        updateEmployeeInfo: build.mutation<void, EmployeeInfoDto>({
            query: (data) => ({
                url: `/v1/lenders/employee/info`,
                method: 'POST',
                data,
            }),
            invalidatesTags: (data, error, args) => [
                { type: 'EmployeePublicDetailsDto', id: "LIST" },
                { type: 'EmployeePublicDetailsDto', id: args.employeeId }
            ],
        }),
        getEmployeePublicDetails: build.query<EmployeePublicDetailsDto, { id: string }>({
            query: (data) => ({
                url: `/v1/lenders/employeePublicDetails/${data.id}`,
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [
                { type: 'EmployeePublicDetailsDto', id: 'LIST' },
                {
                    type: 'EmployeePublicDetailsDto', id: arg.id
                }
            ],
        }),
        getEmployeeSecureUploadPageUrl: build.query<UniqueIdDto, { givenName: string, familyName: string }>({
            query: (data) => ({
                url: `/v1/lenders/employee/secureUploadPageUrl`,
                method: 'GET',
                data
            }),
        }),
        notifyCompanyShoeBox: build.mutation<void, { companyId: string }>({
            query: (data) => ({
                url: `/v1/noauth/notifyCompany/${data.companyId}/shoebox`,
                method: 'POST',
            }),
        }),
        setPhases: build.mutation<void, LoanPhaseDto[]>({
            query: (data) => ({
                url: `/v1/lenders/phases`,
                method: 'POST',
                data: data
            }),
            invalidatesTags: ["LoanPhaseDto"],
            onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
                // optimistic update
                const patchResult = dispatch(companyApi.util.updateQueryData('getPhases', { companyId: data[0].lenderId }, (draft) => {
                    Object.assign(draft, sortPhasesByDisplayOrder(data))
                }))
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        getPhases: build.query<LoanPhaseDto[], { companyId: string }>({
            query: (data) => ({
                url: `/v1/lenders/${data.companyId}/phases`,
                method: 'GET',
            }),
            providesTags: ["LoanPhaseDto"],
            transformResponse: (response: LoanPhaseDto[]) => {
                // sort by displayOrder
                return sortPhasesByDisplayOrder(response)
            }
        }),
        deletePhases: build.mutation<void, LoanPhaseDto[]>({
            query: (data) => ({
                url: `/v1/lenders/phases`,
                method: 'DELETE',
                data: data
            }),
            invalidatesTags: ["LoanPhaseDto"],
            onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
                // optimistic update
                const patchResult = dispatch(companyApi.util.updateQueryData('getPhases', { companyId: data[0].lenderId }, (draft) => {
                    draft = draft.filter((phase) => !data.some((deletedPhase) => deletedPhase.id === phase.id))
                }))
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        getLogoForACompany: build.query<Document, { companyId: string }>({
            query: (data) => ({
                url: `/v1/lenders/logo/${data.companyId}`,
                method: 'GET',
            }),
        }),
        getLendersForBorrower: build.query<CompanyListDto[], void>({
            query: () => ({
                url: `/v1/lenders/forBorrower`,
                method: 'GET',
            }),
        }),

    }),
    overrideExisting: true,
})

export const {
    useDeletePhasesMutation,
    useGetLogoForACompanyQuery,
    useGetLendersForBorrowerQuery,
    useGetPhasesQuery,
    useIsSubdomainAvailableMutation,
    useLazyGetEmployeeSecureUploadPageUrlQuery,
    useGetEmployeeSecureUploadPageUrlQuery,
    useAddCompanyUserMutation,
    useUpdateCompanyUserMutation,
    useSetPhasesMutation,
    useUpdateEmployeeInfoMutation,
    useGetCompanyShoeBoxesQuery,
    useGetEmployeePublicDetailsQuery,
    useGetCompanyEmployeesQuery,
    useNotifyCompanyShoeBoxMutation
} = companyApi;



