// map javascript constants to list

import { LoanPhaseCategoryType, PerUserConstants } from "src/backend";
import { ListItem, LoanPhaseListItem } from "src/slices/lists";

export const mapConstantsToList = (data: PerUserConstants): {
    states: ListItem[],
    loanStatuses: ListItem[],
    loanTypes: ListItem[],
    entityTypes: ListItem[],
    knowledgeBases: ListItem[],
    systemUsersIds: string[],
    phaseCategories: LoanPhaseListItem[],
    priorities: ListItem[]

} => {
    const priorities: ListItem[] = [];
    let loanStatuses: ListItem[] = [];
    let loanTypes: ListItem[] = [];
    let entityTypes: ListItem[] = [];
    let knowledgeBases: ListItem[] = [];
    const systemUsersIds: string[] = [];
    let phaseCategories: LoanPhaseListItem[] = [];


    const states: ListItem[] = Object.keys(data.statesMap ?? {}).map(key => (
        {
            label: data.statesMap[key]?.unabbreviated,
            value: key
        }
    ))

    loanStatuses = Object.keys(data.loanStatusMap).map((statusSlug) => ({
        label: data.loanStatusMap[statusSlug].label,
        value: statusSlug
    }))

    data.usersExcludedFromCounts.forEach((id: string) => {
        systemUsersIds.push(id);
    });

    const knowledgeBaseKeys = Object.keys(data.knowledgeBaseMap)
    knowledgeBases = knowledgeBaseKeys.map((knowledgeBaseSlug) => ({
        label: data.knowledgeBaseMap[knowledgeBaseSlug].label ?? knowledgeBaseSlug,
        value: knowledgeBaseSlug
    })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

    Object.keys(data.priorityMap).forEach((prioritySlug) => {
        priorities.push({
            label: data.priorityMap?.[prioritySlug].label ?? prioritySlug,
            value: prioritySlug
        })
    });

    loanTypes = Object.keys(data.loanTypeMap ?? {}).map((statusSlug) => ({
        label: data.loanTypeMap[statusSlug].label,
        value: statusSlug
    }))
        .sort((a, b) => a.label.localeCompare(b.label));

    if (loanTypes.length > 1) {
        loanTypes.unshift({ label: "Choose Loan Type", value: "INVALID" })
    }
    const EntityTypeKeys = Object.keys(data.entityTypeMap)
    entityTypes = EntityTypeKeys.map((statusSlug) => ({
        label: data.entityTypeMap[statusSlug].label,
        value: statusSlug
    }))

    entityTypes.unshift({ label: "Choose Entity Type", value: "INVALID" })

    Object.keys(data.phaseCategoryMap).forEach((phaseCategorySlug) => {
        phaseCategories.push({
            label: data.phaseCategoryMap?.[phaseCategorySlug].label ?? phaseCategorySlug,
            value: LoanPhaseCategoryType[phaseCategorySlug]
        })
    });

    return {
        states,
        loanStatuses,
        loanTypes,
        entityTypes,
        knowledgeBases,
        systemUsersIds,
        phaseCategories,
        priorities: priorities.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))
    }
};