
import { AnonCreateSessionForUserDto, AnonSessionDto, AppUserAvatarDto, AppUserAzureEmailDto, AppUserDTO2, AppUserPreferencesDTO, FePreferenceEntityResponseDto, NewCreditConsentDTO, OnboardDTO } from 'src/backend';

import { baseApi } from './baseApi';

export const userApi = baseApi.enhanceEndpoints({ addTagTypes: ['FePreferenceEntityResponseDto', 'OnboardDTO', 'AppUserPreferencesDTO', 'AppUserDTO2'] }).injectEndpoints({
    endpoints: (build) => ({
        getLoggedInUser: build.query<OnboardDTO, void>({
            query: () => ({
                url: '/v2/users/loggedUser',
                method: 'GET',
            }),
            providesTags: (response) => {
                return typeof response?.user !== 'undefined'
                    ? [
                        { type: 'AppUserDTO2', id: response.user.id },
                        { type: 'OnboardDTO', id: response.user.id },
                        { type: 'OnboardDTO', id: 'LIST' }
                    ]
                    : []
            },
        }),
        addSoftCreditPull: build.mutation<OnboardDTO, { userId: string, payload: NewCreditConsentDTO }>({
            query: (data) => ({
                url: `/v2/users/${data.userId}/creditConsent`,
                method: 'POST',
                data: data.payload,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        setUserAvatar: build.mutation<AppUserDTO2, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: 'POST',
                data,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        generateSessionForUser: build.mutation<AnonSessionDto, AnonCreateSessionForUserDto>({
            query: (data) => ({
                url: '/v1/auth/anonSession',
                method: 'POST',
                data,
            }),
        }),
        getGenerateSessionForUser: build.query<AnonSessionDto, AnonCreateSessionForUserDto>({
            query: (data) => ({
                url: '/v1/auth/anonSession',
                method: 'POST',
                data,
            }),
        }),
        getKeyPreference: build.query<any, { key: string }>({
            query: (data) => ({
                url: `/v1/fepreference/${data.key}`,
                method: 'GET',
            }),
            transformResponse: (response: FePreferenceEntityResponseDto) => {

                if (typeof response.value === 'undefined' || response.value === null) {
                    return null
                }
                return JSON.parse(decodeURIComponent(response.value))
            },
            providesTags: (_, __, { key }) => [{ type: 'FePreferenceEntityResponseDto', id: key }],
        }),
        setKeyPreference: build.mutation<any, { key: string, value: any }>({
            query: (data) => ({
                url: `/v1/fepreference/${data.key}`,
                method: 'POST',
                data: JSON.stringify(data.value),
            }),
            async onQueryStarted({ key, value }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    userApi.util.updateQueryData('getKeyPreference', { key }, (draft) => {
                        if (draft !== null && typeof draft !== 'undefined') {
                            if (typeof value === 'string') {
                                Object.assign(draft, { value })
                            } else {
                                Object.assign(draft, value)
                            }
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
            invalidatesTags: (_, __, { key }) => [{ type: 'FePreferenceEntityResponseDto', id: key }],
        }),
        getUserSettings: build.query<AppUserPreferencesDTO, void>({
            query: () => ({
                url: '/v1/auth/userSettings',
                method: 'GET',
            }),
            providesTags: ['AppUserPreferencesDTO'],
        }),
        updateUserSettings: build.mutation<AppUserPreferencesDTO, Partial<AppUserPreferencesDTO>>({
            query: (data) => ({
                url: '/v1/auth/userSettings',
                method: 'PUT',
                data,
            }),
            // optimistic update
            async onQueryStarted(patch, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    userApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    dispatch(userApi.util.invalidateTags(['AppUserPreferencesDTO']))
                }
            },
            // invalidate and refetch
            invalidatesTags: ['AppUserPreferencesDTO'],
        }),
        deleteUserAvatar: build.mutation<void, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: "DELETE",
                data
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        updateUserAvatar: build.mutation<AppUserDTO2, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: "PUT",
                data
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        requestExtendSessionForUser: build.mutation<AnonSessionDto, { id: string }>({
            query: (data) => ({
                url: `/v1/auth/anonSession/${data.id}/extend`,
                method: 'POST',
                data,
            }),

        }),
        updateAzureEmail: build.mutation<AppUserDTO2, AppUserAzureEmailDto>({
            query: (data) => ({
                url: `/v2/users/azureEmail`,
                method: 'PUT',
                data,
            }),
            // invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
    }),
    overrideExisting: true,
})

export const {
    useSetKeyPreferenceMutation,
    useGetKeyPreferenceQuery,
    useUpdateAzureEmailMutation,
    useAddSoftCreditPullMutation,
    useGetLoggedInUserQuery,
    useLazyGetLoggedInUserQuery,
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useGenerateSessionForUserMutation,
    useSetUserAvatarMutation,
    useGetGenerateSessionForUserQuery,
    useDeleteUserAvatarMutation,
    useUpdateUserAvatarMutation,
    useRequestExtendSessionForUserMutation,
    useLazyGetKeyPreferenceQuery,
} = userApi;



