import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Router from 'next/router';
import nProgress from 'nprogress';
import React from 'react';
import ReactDOM from 'react-dom'
import { StompSessionProvider } from "react-stomp-hooks";
import { ToastContainer } from 'react-toastify';
import { AppErrorBoundary } from 'src/components/app-error-boundary';
import { AuthProvider } from 'src/contexts/auth-context';
import { WEBSOCKET_SERVER_DEV, WEBSOCKET_SERVER_PROD } from 'src/lib/Socket';
import { isDevelopmentMode, isLocalhost, isServer } from 'src/utils';

import { createTheme } from '../theme';
import { ReduxProvider } from './redux-provider';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

// start bugsnag
Bugsnag.start({
  apiKey: 'c55395ad1b6eb693f246d8a73af4251d',
  plugins: [new BugsnagPluginReact()],
})

const SOCKET_URL = (isDevelopmentMode || isLocalhost) ? WEBSOCKET_SERVER_DEV : WEBSOCKET_SERVER_PROD

const theme = createTheme();

// Create the error boundary...
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export const AppProvider = ({ children }) => {

  return (<StompSessionProvider url={SOCKET_URL}>
    <ReduxProvider>
      <AuthProvider>
        <ThemeProvider theme={createTheme()}>
          <CssBaseline />
          {/* @ts-expect-error */}
          <ErrorBoundary fallback={AppErrorBoundary}>
            {children}
          </ErrorBoundary>
        </ThemeProvider>
      </AuthProvider>
      {!isServer ? ReactDOM.createPortal(<div data-toast="true">
        <ToastContainer
          hideProgressBar
          autoClose={10_000}
          position="bottom-right"
          className="pointer-events-auto mb-16 md:mb-0 px-4 md:px-0"
        />
      </div>, document.body) : <div data-toast="true">
        <ToastContainer
          hideProgressBar
          autoClose={10_000}
          position="bottom-right"
          className="pointer-events-auto mb-16 md:mb-0 px-4 md:px-0"
        />
      </div>}
    </ReduxProvider>
  </StompSessionProvider>)
}