import { ListItem } from "src/slices/lists";

import { ElementModifierType, PriorityType, StorageType } from "../backend";

export const KnowledgeBase = {
    PRINCIPALS: 'PRINCIPALS',
    APPLICANT: 'APPLICANT',
    TRUST: "TRUST",
    PDF_PACKAGE: "PDF_PACKAGE",
    ZIP_PACKAGE: "ZIP_PACKAGE",
    COMPANY: "COMPANY",
    SHOE_BOX: 'SHOE_BOX',
    COLLATERAL_ASSET_REAL_ESTATE: 'COLLATERAL_ASSET_REAL_ESTATE',
    COLLATERAL_ASSET: 'COLLATERAL_ASSET',
    SUBJECT_COLLATERAL: "SUBJECT_COLLATERAL",
    SUBJECT_COLLATERAL_REAL_ESTATE: "SUBJECT_COLLATERAL_REAL_ESTATE"
} as const;

export const Type = {
    SECTION: StorageType.FOLDER,
    FOLDER: StorageType.FOLDER,
    FILE: StorageType.FILE,
} as const;

export const ZIP_IGNORE_FILES = [
    '.DS_Store'
] as const;

export const FILE_CARD_MINIMAL_DISPLAY = 'MINIMAL';

export const FILE_CARD_FULL_DISPLAY = 'FULL';

export const FileCardDisplay = {
    MINIMAL: 'MINIMAL',
    FULL: 'FULL',
} as const;

export const OverViewPackageDisplay = {
    PRINCIPAL: 'PRINCIPAL',
    USER: 'USER'
} as const;

export const FormElementPriority = {
    Hidden: PriorityType.HIDDEN,
    High: PriorityType.HIGH,
    Low: PriorityType.LOW,
    Medium: PriorityType.MEDIUM,
    Private: PriorityType.PRIVATE,
    Urgent: PriorityType.URGENT,
} as const;

export const QUERY_CREATE_FILE_ELEMENT_DIALOG = 'createFileElementDialog';

export const QUERY_PARAM_CONFIRM_TASKS_VIEW_CLOSE_IN_PROGRESS_ELEMENTS = 'tasksInProgressElementsConfirmation';

export const QUERY_PARAM_FORM_ELEMENT_ID = 'formElementId';

export const QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER = 'packageUnAssignedFilter';

export const QUERY_PARAM_FORM_ELEMENT_TITLE = 'formElementTitle';

export const QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID = 'fullScreenElementId';

export const QUERY_PARAM_FORM_ELEMENT_IDS = 'formElementIds';

export const QUERY_PARAM_TEMPLATE_ID = 'templateId';

export const QUERY_PARAM_NEEDS_LIST_DIALOG = 'needsListDialog';

export const QUERY_PARAM_PICK_NEEDS_LIST_LOAN_DIALOG = 'pickNeedsListLoanDialog';

export const DEFAULT_FROM_ELEMENT_DISPLAY_ORDER = 1000;

export const PINNED_FROM_ELEMENT_DISPLAY_ORDER = 1001;

export enum UploadFileStrategy {
    Merge = 'MERGE',
    Replace = 'REPLACE',
    MergeOnly = 'MERGE_ONLY',
};

export enum SimpleViewFolderNames {
    Business = "Business Docs",
    BusinessAndTrust = "Business and Trust Docs",
    Collateral = "Collateral Docs",
    Individual = "Individual Docs",
    Project = "Project Docs",
}

export const elementModifiers: ListItem[] = Object.keys(ElementModifierType).map(key => ({
    label: key,
    value: key
}));