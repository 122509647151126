import sanitizeHtml from 'sanitize-html';

export const htmlToText = (html: string): string => {
    const sanitizedText = sanitizeHtml(html, {
        allowedTags: [],
        allowedAttributes: {},
    });
    const text = sanitizedText.replace(/&nbsp;/g, ' ')
        // strip \n and \r
        .replace(/[\n\r]/g, '')
        // strip multiple spaces
        .replace(/\s{2,}/g, ' ')
        ;
    return text;
};
