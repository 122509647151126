import { j as g } from "./jsx-runtime-B6kdoens.js";
import { $ as h } from "./index-28E3tTLh.js";
import { c as b } from "./index-BV2fynjD.js";
import * as w from "react";
import { c as y } from "./utils-CJ9afRe1.js";
const v = b("", {
  variants: {
    underline: {
      true: "underline"
    },
    capitalize: {
      true: "capitalize"
    },
    italic: {
      true: "italic"
    },
    truncate: {
      true: "truncate"
    },
    select: {
      false: "select-none"
    },
    center: {
      true: "text-center"
    },
    size: {
      x4s: "text-[8px] leading-[10px]",
      x3s: "text-[9px] leading-[11px]",
      xss: "text-[10px] leading-[14px]",
      xs: "text-xs leading-4",
      sm: "text-sm leading-5",
      base: "text-base",
      lg: "text-lg",
      xl: "text-xl",
      xxl: "text-2xl",
      xxxl: "text-3xl",
      inherit: "text-inherit"
    },
    lineClamp: {
      default: "",
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6"
    },
    weight: {
      thin: "font-thin",
      normal: "font-normal",
      medium: "font-medium",
      bold: "font-bold",
      inherit: "font-inherit"
    },
    variant: {
      inherit: "text-inherit",
      primary: "text-black-primary",
      blue: "text-blue-100",
      secondary: "text-gray-neutral-70",
      white: "text-white",
      purple: "text-purple-50",
      destructive: "text-destructive",
      warning: "text-yellow-70",
      success: "text-green-flowkit",
      "black-primary": "text-black-primary"
    }
  },
  defaultVariants: {
    lineClamp: "default",
    truncate: !1,
    center: !1,
    italic: !1,
    size: "base",
    underline: !1,
    select: !0,
    weight: "normal",
    variant: "primary",
    capitalize: !1
  }
}), C = w.forwardRef(
  ({
    className: e,
    size: t,
    weight: a,
    lineClamp: i,
    capitalize: l,
    variant: r,
    as: n,
    select: x,
    italic: s,
    asChild: c,
    underline: p,
    truncate: m,
    center: o,
    ...u
  }, f) => {
    const d = c ? h : n || "p";
    return /* @__PURE__ */ g.jsx(
      d,
      {
        className: y(
          v({
            italic: s,
            truncate: m,
            underline: p,
            capitalize: l,
            size: t,
            select: x,
            weight: a,
            lineClamp: i,
            variant: r,
            className: e,
            center: o
          })
        ),
        ref: f,
        ...u
      }
    );
  }
);
export {
  C as T
};
