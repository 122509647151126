import { PINNED_FROM_ELEMENT_DISPLAY_ORDER, Type } from 'src/constants/form-element';
import { StorageType } from 'src/constants/template';
import { TemplateElementTree } from 'src/types/formelement';


export const sortFromElements = <T>(a: TemplateElementTree<T>, b: TemplateElementTree<T>) => {
    // Check if both items have displayOrder PINNED_FROM_ELEMENT_DISPLAY_ORDER
    if (a.displayOrder === PINNED_FROM_ELEMENT_DISPLAY_ORDER && b.displayOrder === PINNED_FROM_ELEMENT_DISPLAY_ORDER) {
        // If both items are folders, sort alphabetically
        if (a.storageType === StorageType.FOLDER && b.storageType === StorageType.FOLDER) {
            return String(a.title || a.name).localeCompare((b.title || b.name), 'en', { numeric: true });
            // If both items are files, sort alphabetically
        } else if (a.storageType === StorageType.FILE && b.storageType === StorageType.FILE) {
            return String(a.title || a.name).localeCompare((b.title || b.name));
            // If one item is a folder and the other is a file, prioritize the folder
        } else if (a.storageType === StorageType.FOLDER && b.storageType === StorageType.FILE) {
            return -1;
        } else {
            return 1;
        }
        // If only one item has displayOrder PINNED_FROM_ELEMENT_DISPLAY_ORDER, prioritize that item
    } else if (a.displayOrder === PINNED_FROM_ELEMENT_DISPLAY_ORDER && b.displayOrder !== PINNED_FROM_ELEMENT_DISPLAY_ORDER) {
        return -1;
    } else if (a.displayOrder !== PINNED_FROM_ELEMENT_DISPLAY_ORDER && b.displayOrder === PINNED_FROM_ELEMENT_DISPLAY_ORDER) {
        return 1;
        // If neither item has displayOrder PINNED_FROM_ELEMENT_DISPLAY_ORDER, sort by storageType and then title
    } else {
        // If both items are folders, sort alphabetically
        if (a.storageType === StorageType.FOLDER && b.storageType === StorageType.FOLDER) {
            return String(a.title || a.name).localeCompare((b.title || b.name), 'en', { numeric: true });
            // If both items are files, sort alphabetically
        } else if (a.storageType === StorageType.FILE && b.storageType === StorageType.FILE) {
            return String(a.title || a.name).localeCompare((b.title || b.name), 'en', { numeric: true });
            // If one item is a folder and the other is a file, prioritize the folder
        } else if (a.storageType === StorageType.FOLDER && b.storageType === StorageType.FILE) {
            return -1;
        } else {
            return 1;
        }
    }
};