import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { api } from 'src/api'
import { DocumentAnswerV2Dto, FormElementV2RequestDto, FormElementV2ResponseDto, LoanDto, LoanViewType, NewLoanTemplateDto } from 'src/backend';
import { HTTP_STATUS } from 'src/constants/api';
import { ZipFileAction, ZipStatus } from 'src/constants/common';
import { DEFAULT_FROM_ELEMENT_DISPLAY_ORDER, OverViewPackageDisplay, Type as FormElementType } from 'src/constants/form-element';
import { ZIP_MIME_TYPES } from 'src/constants/mimes';
import { StorageType } from 'src/constants/template';
import { loanApi } from 'src/services/loanApi';
import { packageApi } from 'src/services/packageApi';
import { addIdToUploadingIds, removeFormElementUploadingProgress, removeIdFromUploadingIds, setFormElementUploadingProgress, uploadDocument } from 'src/slices/documents';
import { getAllLoanUsersTasks } from 'src/slices/task';
import { setZipFileActionCallback } from 'src/slices/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { ZipFileElement } from 'src/types/common';
import { FormElementV2ResponseDtoExtended, TemplateElementTree } from 'src/types/formelement';
import type { FormElement } from 'src/types/view';
import { getFileNameWithoutExtension } from 'src/utils';
import { isFormElementEntityTopLevel } from 'src/utils/form-element/is-form-element-entity-top-level';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { sortFromElements } from 'src/utils/form-element-transformer';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { toast } from 'src/utils/toast';
import { getFoldersAndFiles, getZipStatus } from 'src/utils/zip';

import { elementsTree } from './elementv2';
import { licenseKeysSlice } from './license-keys';
import { getLoan } from './loan';
import { loansManagerSlice } from './loan-manager';

interface FormElementState {
    // this holds the number
    // of a form element being touched
    pendingGetLoanFormElements?: Record<string, string>;
    currentlyEditing?: FormElement;
    isPreviewDocumentPinned: boolean;
    idToDeleteAfterUpload: string;
    zipPasswordCallback?: (password: string) => void;
    shoeBoxFilesCount: number;
    allShoeBoxes: Record<string, Record<string, FormElement>>;
    expandedShoeBox: string;
    cardsDisplay: keyof typeof OverViewPackageDisplay;
    updatingFormElementIds: Record<string, string>;
    loading: boolean;
    optimisticElementFileUploads: Record<string, string>;
}

const initialState: FormElementState = {
    pendingGetLoanFormElements: {},
    currentlyEditing: null,
    isPreviewDocumentPinned: false,
    idToDeleteAfterUpload: null,
    shoeBoxFilesCount: 0,
    expandedShoeBox: null,
    allShoeBoxes: {},
    cardsDisplay: OverViewPackageDisplay.PRINCIPAL,
    updatingFormElementIds: {},
    loading: false,
    optimisticElementFileUploads: {}
};

export const formElementSlice = createSlice({
    name: 'formElement',
    initialState,
    reducers: {
        addIdToUpdating(state: FormElementState, action: PayloadAction<string>): void {
            state.updatingFormElementIds[action.payload] = action.payload;
        },
        removeIdFromUpdating(state: FormElementState, action: PayloadAction<string>): void {
            delete state.updatingFormElementIds[action.payload];
        },
        setCurrentlyEditingFormElement(state: FormElementState, action: PayloadAction<FormElement | null>): void {
            state.currentlyEditing = action.payload
        },
        setPendingGetFormElements(state: FormElementState, action: PayloadAction<string>): void {
            // add or remove pending get loan form elements
            if (state.pendingGetLoanFormElements[action.payload]) {
                delete state.pendingGetLoanFormElements[action.payload]
            } else {
                state.pendingGetLoanFormElements[action.payload] = action.payload
            }
        },
        setDocumentPreviewPinned(state: FormElementState, action: PayloadAction<boolean>): void {
            state.isPreviewDocumentPinned = action.payload
        },
        setIdToDeleteAfterUpload(state: FormElementState, action: PayloadAction<string>): void {
            state.idToDeleteAfterUpload = action.payload;
        },
        setZipPasswordCallback(state: FormElementState, action: PayloadAction<(password: string) => void>): void {
            state.zipPasswordCallback = action.payload
        },
        setExpandedShoeBox(state: FormElementState, action: PayloadAction<string>): void {
            state.expandedShoeBox = action.payload
        },
        setCardsDisplay(state: FormElementState, action: PayloadAction<keyof typeof OverViewPackageDisplay>): void {
            state.cardsDisplay = action.payload
        },
        setLoading(state: FormElementState, action: PayloadAction<boolean>): void {
            state.loading = action.payload
        },
        setOptimisticElementFileUploads(state: FormElementState, action: PayloadAction<{ elementId: string, fileName: string }>): void {
            state.optimisticElementFileUploads[action.payload.elementId] = action.payload.fileName;
        }
    }
});

export const { reducer } = formElementSlice;

export const addIdToUpdating = (formElementId: string): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.addIdToUpdating(formElementId));
}


export const removeIdFromUpdating = (formElementId: string): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.removeIdFromUpdating(formElementId));
}

export const setOptimisticElementFileUploads = (args: { elementId: string, fileName: string }): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.setOptimisticElementFileUploads(args));
}

export const setCardsDisplay = (cardsDisplay: keyof typeof OverViewPackageDisplay, loanId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setCardsDisplay(cardsDisplay));
    await dispatch(getLoanFormElements(loanId));
}

export const setZipPasswordCallback = (callback: (password: string) => void): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setZipPasswordCallback(callback));
}

const uploadZipFile = ({ formElementId, loanId, files, dadZipFileHandling }: { formElementId: string, loanId: string, files: File[], dadZipFileHandling: string }): AppThunkPromise => async (dispatch, getState): Promise<any> => {
    dispatch(addIdToUploadingIds(formElementId));
    const promises = files.map(async file => {
        return new Promise(async (resolve, reject) => {
            const zipStatus = await getZipStatus(file);
            let password = undefined;
            if (zipStatus === ZipStatus.ENCRYPTED) {
                password = await new Promise((resolvePassword) => {
                    dispatch(formElementSlice.actions.setZipPasswordCallback(resolvePassword));
                });
                dispatch(formElementSlice.actions.setZipPasswordCallback(null));
            }
            let action = undefined;
            if (dadZipFileHandling === ZipFileAction.ASK) {
                action = await new Promise((resolveAction) => {
                    dispatch(setZipFileActionCallback(resolveAction));
                })
            }
            if (action === ZipFileAction.IGNORE) {
                dispatch(uploadMultipleFilesAndAnswerFormElement({
                    files,
                    parentId: formElementId,
                    loanId,
                    ignoreZip: true,
                    dadZipFileHandling
                }));
                reject();
            } else {

                try {
                    const { tree } = await getFoldersAndFiles(file, password);
                    await dispatch(recursivelyCreateFormElements({ tree, formElementId, loanId }));
                } catch (error) {
                    toast({
                        content: error.message,
                        type: 'error'
                    });
                    reject(error);
                }
            }
            resolve(true);
        })
    });
    await Promise.allSettled(promises);
    dispatch(removeIdFromUploadingIds(formElementId));
}

export const recursivelyCreateFormElements = ({ formElementId, loanId, tree }: { formElementId: string, loanId: string, tree: ZipFileElement[] }): AppThunkPromise => async (dispatch): Promise<void> => {
    for (const element of tree) {
        const { name, type } = element;
        dispatch(addIdToUploadingIds(formElementId));
        const [createdFormElement] = await dispatch(
            createFormElements({
                formElements: [{
                    title: name,
                    storageType: type,
                    assignedToUserId: null,
                    displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
                    hasExpiration: false,
                    parentId: formElementId,
                    loanId,
                }],
                loanId
            })
        );
        if (type === "FOLDER" && createdFormElement?.id) {
            await dispatch(recursivelyCreateFormElements({ formElementId: createdFormElement.id, loanId, tree: element.children }));
        } else if (type === FormElementType.FILE && createdFormElement?.id) {
            await dispatch(uploadDocument({
                formElement: createdFormElement,
                loanId,
                file: element.file,
                type: 'FormElement',
                isMerged: false
            }));
        }
        dispatch(removeIdFromUploadingIds(formElementId));
    }
}

const updateFormElement = ({ formElementId, loanId, payload }: {
    formElementId: string,
    loanId: string,
    payload: {
        title: string;
        assignedToUserId: string;
        hasExpiration: boolean;
        expireDate: string;
    }
}): AppThunkPromise => async (dispatch): Promise<any> => {


    const { statusCode, errors, data } = await api.putFormElement({ formElementId, payload });
    if (statusCode === HTTP_STATUS.OK) {
        dispatch(getLoanFormElements(loanId));
        return { data }
    } else {
        return { errors };
    }
}


export const deleteFormElements = ({ multiSelect = false, formElementIds, loanId }: { multiSelect: boolean; formElementIds: string[], loanId: string }): AppThunkPromise<boolean> => async (dispatch): Promise<boolean> => {
    try {
        await api.deleteV2FormElements({
            multiSelect,
            elements: formElementIds.map(id => ({
                id: id,
                loanId
            })) as FormElementV2RequestDto[]
        });
        dispatch(packageApi.util.invalidateTags([
            {
                type: 'FormElementsV2ResponseDto',
                id: loanId
            }
        ]));
        dispatch(deleteFormElementsEntities({ formElementIds, loanId }));
        dispatch(getLoanFormElements(loanId));
        dispatch(getLoan(loanId));
        return true;
    } catch {
        return false;
    }
}

const deleteFormElementsEntities = ({ formElementIds, loanId }: { formElementIds: string[], loanId: string }): AppThunk => async (_, getState): Promise<void> => {
    const loanFormElements = await api.getV2FormElements({ loanId });
    const { [loansManagerSlice.name]: { loans } } = getState();
    const loan = loans[loanId];
    if (!loanFormElements || !loan) return;
    const loanFormElementsList = Object.values(loanFormElements)
    const entityFormElements = loanFormElementsList
        // get all form elements set to be deleted
        .filter(formElement => formElementIds.includes(formElement.id))
        // get all form elements that are entities
        .filter(formElement => !!formElement.sherpaEntityId)
        // get all form elements that are top level entities
        .filter(formElement => isFormElementEntityTopLevel(formElement, loanFormElementsList));

    const entitiesList = loan.loanEntities.filter(entity => entityFormElements.some(formElement => formElement.sherpaEntityId === entity?.sherpaEntity?.id));

    entitiesList.forEach(entity => {
        api.removeEntityFromLoan(loanId, entity.id);
    });
}


export const approveFormElements = ({ multiSelect = false, formElements, loanId, userId }: { multiSelect: boolean; formElements: FormElementV2ResponseDto[], loanId: string, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const formElementsWithFiles = formElements.filter(formElement => formElement.storageType === FormElementType.FILE && !!formElement?.answer?.document?.id);
    if (formElementsWithFiles.length > 0) {
        try {
            dispatch(updateFormElements({
                multiSelect,
                formElements: formElementsWithFiles.map(formElement => ({
                    id: formElement.id,
                    approvedByUserId: userId,
                    loanId
                } as FormElementV2RequestDto)),
                loanId
            }));
        } catch (error) {
        }
    }
}

export const rejectFormElements = ({ multiSelect = false, formElements, loanId, userId }: { multiSelect: boolean; formElements: FormElementV2ResponseDto[], loanId: string, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const formElementsWithFiles = formElements.filter(formElement => formElement.storageType === FormElementType.FILE && !!formElement?.answer?.document?.id);
    if (formElementsWithFiles.length > 0) {
        try {
            dispatch(updateFormElements({
                multiSelect,
                formElements: formElementsWithFiles.map(formElement => ({
                    id: formElement.id,
                    rejectedByUserId: userId,
                    loanId
                } as FormElementV2RequestDto)),
                loanId
            }));
        } catch (error) {
        }
    }
}

export const unApproveFormElement = (args: { formElements: FormElementV2ResponseDto[], loanId: string, multiSelect: boolean, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    try {
        dispatch(updateFormElements({
            multiSelect: false,
            formElements: args.formElements.map(formElement => ({
                id: formElement.id,
                approved: false,
                loanId: args.loanId
            })),
            loanId: args.loanId
        }));
    } catch (error) {

    }
}

// answer form element
export const answerFileFormElement = ({ submit = null, formElementId, documentId, loanId, answerId = null, isMerged }): AppThunkPromise => async (dispatch, getState): Promise<FormElementV2ResponseDto> => {
    const { formElement: { idToDeleteAfterUpload } } = getState();
    if (idToDeleteAfterUpload) {
        dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
    }
    const formElement = await api.addAnswerToV2Element({
        answerId,
        elementId: formElementId,
        documentId,
        submit,
        isMerged
    });
    dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
    dispatch(packageApi.util.invalidateTags([
        {
            type: 'FormElementsV2ResponseDto',
            id: loanId
        }
    ]));
    await dispatch(getLoanFormElements(loanId));
    dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
    dispatch(setIdToDeleteAfterUpload(null));
    dispatch(getAllLoanUsersTasks(loanId));
    return formElement;
}

export const addDocumentSection = ({ document, formElementId, loanId }: { document: DocumentAnswerV2Dto, formElementId: string, loanId: string }): AppThunkPromise => async (dispatch, getState): Promise<FormElementV2ResponseDto> => {
    const { formElement: { idToDeleteAfterUpload } } = getState();
    if (idToDeleteAfterUpload) {
        dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
    }
    try {
        const result = await api.createV2FormElements({
            elements: [{
                title: getFileNameWithoutExtension(document.name),
                storageType: StorageType.FILE,
                displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
                hasExpiration: false,
                parentId: formElementId,
                loanId
            }],
        });
        await dispatch(packageApi.endpoints.getLoanElements.initiate(
            { id: loanId, view: LoanViewType.CONVENTIONAL },
            { subscribe: false, forceRefetch: true },
        ));
        const createdPackageInfoList = Object.values(result.packageInfo);
        const [addedFormElement] = createdPackageInfoList.map(packageInfo => {
            return ({
                ...result.elements[packageInfo.elementId],
                id: packageInfo.id,
                parentId: packageInfo.parentInfoId,
                childrenIds: packageInfo.childrenIds,
                description: packageInfo.description,
                includeDescription: packageInfo.includeDescription,
                title: packageInfo.title,
                locations: packageInfo.locations,
            })
        })
        await api.addAnswerToV2Element({
            answerId: null,
            elementId: addedFormElement.id,
            documentId: document.id,
            submit: null,
            isMerged: false
        });
        const pendingRequest = packageApi.util.getRunningOperationPromise('getLoanElements', {
            id: loanId,
            view: LoanViewType.CONVENTIONAL
        })
        await pendingRequest
        dispatch(loanApi.util.invalidateTags([
            { type: 'FormElementsV2ResponseDto', id: loanId },
            { type: 'LoanDto', id: loanId },
            { type: 'BasicLoanDto', id: loanId }
        ]));

        await dispatch(packageApi.endpoints.getLoanElements.initiate(
            { id: loanId, view: LoanViewType.CONVENTIONAL },
            { subscribe: false, forceRefetch: true },
        ));
        if (document.createdByUser) {
            await dispatch(getAllLoanUsersTasks(loanId));
        }
        if (idToDeleteAfterUpload) {
            dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
            dispatch(setIdToDeleteAfterUpload(null));
        }

        return addedFormElement;
    } catch (error) {
        notifyBugTracker(error);
        return null
    }
}

// get upload url for multiple files and upload them and create child form element for each and answer them
export const uploadMultipleFilesAndAnswerFormElement = ({
    extraFields = [],
    parentId,
    loanId,
    files,
    ignoreZip,
    dadZipFileHandling,
    sherpaEntityId }: { parentId: string, loanId: string, files: File[], dadZipFileHandling: string, ignoreZip?: boolean, sherpaEntityId?: string, extraFields?: Partial<FormElementV2RequestDto>[] }): AppThunk => async (dispatch, getState: () => RootState): Promise<void> => {
        const { formElement: { idToDeleteAfterUpload }, [licenseKeysSlice.name]: { pdftronKey } } = getState();

        // dispatch upload zip file
        const zipFiles = files.filter(file => ZIP_MIME_TYPES.includes(file.type as any) &&
            dadZipFileHandling !== ZipFileAction.IGNORE &&
            !ignoreZip);
        if (zipFiles.length) {
            dispatch(uploadZipFile({ files: zipFiles, loanId, formElementId: parentId, dadZipFileHandling }));
        }
        // upload other document the normal way
        const filteredIndexes = [];

        const otherFiles = files.filter((file, index) => {
            if (!ZIP_MIME_TYPES.includes(file.type as any) ||
                dadZipFileHandling === ZipFileAction.IGNORE ||
                ignoreZip) {
                filteredIndexes.push(index);
                return true;
            }

            return false
        });

        const filteredFields = extraFields.filter((_field, index) => filteredIndexes.includes(index));

        if (otherFiles.length === 0) {
            return;
        }
        dispatch(addIdToUploadingIds(parentId));
        const clearUploadProgressIds = [];
        try {
            const createFileFormElementsPromises = [];
            otherFiles.forEach((file, index) => {
                createFileFormElementsPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        const [createdFormElement] = await dispatch(createFormElements({
                            formElements: [{
                                displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
                                title: getFileNameWithoutExtension(file.name),
                                storageType: StorageType.FILE,
                                hasExpiration: false,
                                parentId,
                                sherpaEntityId,
                                loanId,
                                ...filteredFields[index]
                            }],
                            loanId
                        }));
                        dispatch(addIdToUploadingIds(createdFormElement.id));
                        const uploadResult = await uploadFileToDocumentId({
                            file,
                            loanId,
                            formElementId: createdFormElement.id,
                            name: createdFormElement.title,
                            pdfTronKey: pdftronKey,
                            progress: (percent) => {
                                dispatch(setFormElementUploadingProgress(createdFormElement.id, percent));
                                if (percent >= 100) {
                                    dispatch(setFormElementUploadingProgress(createdFormElement.id, null));
                                    dispatch(removeIdFromUploadingIds(createdFormElement.id));
                                    clearUploadProgressIds.push(createdFormElement.id);
                                }
                            }
                        });
                        dispatch(setFormElementUploadingProgress(createdFormElement.id, null));
                        await dispatch(answerFileFormElement({
                            formElementId: createdFormElement.id,
                            documentId: uploadResult.documentId,
                            loanId,
                            submit: null,
                            isMerged: false
                        }));
                        dispatch(removeIdFromUploadingIds(createdFormElement.id));
                        clearUploadProgressIds.push(createdFormElement.id);
                        resolve(true);
                    } catch (error) {
                        reject(error);
                    }
                }));
            });

            const results = await Promise.allSettled(createFileFormElementsPromises);

            // get the last fulfilled promise
            const hasFulfilled = results.find(result => result.status === 'fulfilled');
            if (!hasFulfilled) {
                dispatch(setIdToDeleteAfterUpload(null));
                throw Error("Couldn't create form elements");
            }
            if (idToDeleteAfterUpload) {
                dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
            }

            if (!!hasFulfilled) {
                await dispatch(getLoanFormElements(loanId));
            }
        } catch (error) {
            notifyBugTracker(error);
        } finally {
            if (idToDeleteAfterUpload) {
                dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
            }
            dispatch(setIdToDeleteAfterUpload(null));
            dispatch(removeIdFromUploadingIds(parentId));
            clearUploadProgressIds.forEach(id => dispatch(removeFormElementUploadingProgress(id)));
        }
    }

export const getLoanFormElements = (loanId: string, clearPendingRequest: boolean = false, loanViewType?: LoanViewType): AppThunkPromise => async (dispatch): Promise<void> => {
    dispatch(packageApi.util.invalidateTags([{ type: 'FormElementsV2ResponseDto', id: loanId }]))
    dispatch(getAllLoanUsersTasks(loanId))
}

export const updateFormElements = ({ multiSelect = false, formElements, loanId }: { multiSelect: boolean, formElements: Partial<FormElementV2RequestDto>[], loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    if (formElements.length > 0) {
        await api.updateV2FormElements({
            multiSelect,
            elements: formElements,
        });
        dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
        dispatch(loanApi.util.invalidateTags([{ type: 'FormElementsV2ResponseDto', id: loanId }]));
        dispatch(packageApi.util.invalidateTags([{
            type: 'FormElementsV2ResponseDto',
            id: loanId
        }]));
        await dispatch(getLoanFormElements(loanId));
    }
}

export const applyNewTemplateToExistingLoan = (args: NewLoanTemplateDto): AppThunkPromise<LoanDto> => async (dispatch): Promise<LoanDto> => {
    const loan = await api.applyNewTemplateToExistingLoan(args);
    dispatch(getLoanFormElements(args.loanId));
    return loan;
}

export const createFormElements = ({ formElements, loanId }: { formElements: Partial<FormElementV2RequestDto>[], loanId: string }): AppThunkPromise<FormElementV2ResponseDto[]> => async (dispatch): Promise<FormElementV2ResponseDto[]> => {
    if (formElements.length > 0) {

        const result = await api.createV2FormElements({
            elements: formElements,
        });
        dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
        dispatch(loanApi.util.invalidateTags([{ type: 'FormElementsV2ResponseDto', id: loanId }]));
        dispatch(packageApi.util.invalidateTags([{
            type: 'FormElementsV2ResponseDto',
            id: loanId
        }]));

        dispatch(getLoanFormElements(loanId));
        if (result?.packageInfo) {
            const createdPackageInfoList = Object.values(result.packageInfo);
            return createdPackageInfoList.map(packageInfo => {
                return ({
                    ...result.elements[packageInfo.elementId],
                    id: packageInfo.id,
                    parentId: packageInfo.parentInfoId,
                    childrenIds: packageInfo.childrenIds,
                    description: packageInfo.description,
                    includeDescription: packageInfo.includeDescription,
                    title: packageInfo.title,
                    locations: packageInfo.locations,
                })
            })
        }
    }
}


export const setDocumentPreviewPinned = (pinned: boolean): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setDocumentPreviewPinned(pinned));
}

export const deleteFormElementAnswer = ({ formElement }: { formElement: Partial<FormElementV2ResponseDto> }): AppThunkPromise<boolean> => async (dispatch): Promise<boolean> => {
    try {
        await api.deleteAnswerFromV2Element({
            elementId: formElement.id,
            answerId: formElement.answer.id,
            documentId: formElement.answer.document.id,
            submit: null,
            isMerged: false
        });
        dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: formElement.loanId }, { type: 'LoanDto', id: formElement.loanId }]));
        dispatch(packageApi.util.invalidateTags([{
            type: 'FormElementsV2ResponseDto',
            id: formElement.loanId
        }]));
        dispatch(getLoanFormElements(formElement.loanId));
        return true;
    } catch {
        toast({
            content: `Unable to delete answer for ${formElement.title}`,
            type: 'error'
        });
        return false;
    }
}

const setIdToDeleteAfterUpload = (id: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setIdToDeleteAfterUpload(id));
}

export const resetFormElementAnswer = ({ formElementId, loanId }: { formElementId: string, loanId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(addIdToUploadingIds(formElementId));
    await api.resetTemplateAnswerOnElement(formElementId, { id: formElementId });
    dispatch(getLoanFormElements(loanId));
    dispatch(removeIdFromUploadingIds(formElementId));
}

export const selectFormElementIdIsUpdating = (loanId: string) => createSelector(
    (state: RootState) => state.formElement.updatingFormElementIds,
    (updatingFormElementIds) => updatingFormElementIds?.[loanId] !== undefined
);


export const getEntityElementsList = (elements: FormElementV2ResponseDtoExtended[], entityId: string = null): FormElementV2ResponseDtoExtended[] => {
    const entityFormElements = elements
        .filter((formElement) => formElement.sherpaEntityId === entityId)

    if (!entityFormElements) {
        return []
    }
    // @ts-expect-error
    return entityFormElements.sort(sortFromElements);
}

export const getEntityElementsTree = (elements: FormElementV2ResponseDtoExtended[], entityId: string = null): TemplateElementTree<FormElementV2ResponseDtoExtended>[] => {
    const entityFormElements = elements
        .filter((formElement) => formElement.sherpaEntityId === entityId)
        // group folders with same sherpaEntityId
        .reduce((acc, formElement) => {
            // if there is already a folder with same sherpaEntityId
            // combine children
            const found = acc.find((item) => item.sherpaEntityId === formElement.sherpaEntityId && formElement.storageType === "FOLDER");
            if (found) {
                const newFound = {
                    ...found,
                    childrenIds: [...found.childrenIds, ...formElement.childrenIds]
                };
                return [
                    ...acc.filter((item) => item.id !== found.id),
                    newFound
                ]
            } else if (formElement.storageType !== "FOLDER") {
                return [...acc, formElement];
            } else {
                return acc
            }
        }, [] as FormElementV2ResponseDto[]);

    // filter form elements that exist in another form element childrenIds
    const filteredEntityFormElements = entityFormElements.filter((formElement) => {
        const parentFormElement = entityFormElements.find((item) => item.id === formElement.parentId);
        if (!parentFormElement) {
            return true;
        }
        return !parentFormElement.childrenIds.includes(formElement.id);
    });


    const entityFormElementsIds = filteredEntityFormElements.map((formElement) => formElement.id);
    if (!entityFormElementsIds.length) {
        return []
    }
    return elementsTree(elements, 'id', entityFormElementsIds)
}

export const selectElementOptimisticFileName = (elementId: string) => createSelector(
    (state: RootState) => state.formElement.optimisticElementFileUploads,
    (optimisticElementFileUploads) => optimisticElementFileUploads[elementId]
);