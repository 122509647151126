import { ListItem } from "src/slices/lists";

import {
    BlockType as _BlockType,
    EntityModifierType,
    LoanEntityLabel as BackendLabel,
    LoanViewType as _LoanViewType,
    SherpaEntityType as BackendSherpaEntityType,
} from "../backend";

export const BlockType = {
    None: _BlockType.NO_BLOCK,
    Loan: _BlockType.LOAN,
    Entity: _BlockType.ENTITY,
}

export const LoanViewType = {
    None: _LoanViewType.NO_VIEW,
}

export { StorageType } from "../backend"

export const TemplateQueryParam = {
    templateId: 'templateId',
}

export const LoanEntityLabel = {
    Applicant: BackendLabel.APPLICANT,
    PrimaryCollateral: BackendLabel.PRIMARY_COLLATERAL,
    Member: BackendLabel.MEMBER,
};

export const entityModifiers: ListItem[] = Object.keys(EntityModifierType).map(key => ({
    label: EntityModifierType[key as keyof typeof EntityModifierType],
    value: EntityModifierType[key as keyof typeof EntityModifierType],
}));