import { type ThemeOptions } from '@mui/material/styles';

// Colors

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        desktop: true;
        xxl: true;
    }
}

export const themeV2 = {
    utility: {
        gradient: {
            blue: 'linear-gradient(170.76deg, #FFFFFF 37.85%, #F4F7F9 107.86%)'
        },
    },
    shape: {
        borderRadius: {
            default: '4px',
            button: '6px',
            input: '8px',
        }
    },
    colors: {
        black: {
            neutral: '#090E28'
        },
        purple: {
            10: "#F4F2FD",
            30: '#BDB0FF',
            50: '#5B5E6F',
            100: "#A67EB1",
            200: '#7B61FF',
        },
        yellow: {
            10: '#FDF8EB',
            15: "#FEE9DD",
            dark: '#644D08',
            100: '#FAC014',
            200: "#FF9A61",
            400: "#EC671C",
            800: "#B98C08"
        },
        green: {
            5:"#E1F6EA",
            10: '#F1F8EE',
            20: "#F3FEFA",
            50: '#19C009',
            60: "#22A858",
            100: '#1C6515'
            
        },
        red: {
            5: "#FFEFEF",
            10: "#FEF2F2",
            20: "#FFE5E5",
            50: "#FC5555",
            100: '#AB0D0D',
            dark: "#B45C5C"
        },
        blue: {
            5: "#E8F4FF",
            10: '#F4F6FF',
            20: "#A5D4FF",
            40: "#2A94F6",
            50: '#0099FF',
            60: '#8091DD',
            100: "#2B48C7",
            800: "#101B4B",
            900: "#090E28"
        }
    },
    neutral: {
        grey: {
            5: '#EEEEEE',
            6: '#EEEEF3',
            7: '#E5EAEF',
            8: "#E8E8F0",
            10: '#F8F8FA',
            20: '#E1E1E1',
            50: '#E8EAF0',
            60: "#E4E4EF",
            default: '#F5F5F5',
            70: '#F2F3F5',
            80: '#DBDADA',
            90: '#6A6D7D',
            100: '#939393',
            200: '#6A6D7D'
        },
        white: {
            default: '#FEFEFE'
        }
    }
};

export const folder = {
    light: '#FFE89A',
    main: '#fdb900',
    shadow1: '#fdb90030',
    shadow2: '#fdb90040',
}

export const neutral = {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#002D74'
};

const background = {
    default: '#F9FAFC',
    paper: '#FFFFFF'
};

const divider = '#E6E8F0';

export const primary = {
    contrastText: '#ffffff',
    main: '#3E509E',
    light: '#6A77C9',
    dark: '#002D74',
};

export const secondary = {
    contrastText: '#ffffff',
    main: '#63656A',
    light: '#939598',
    dark: '#000000'
};

export const success = {
    contrastText: '#ffffff',
    main: '#19C009',
    light: '#B3C197',
    dark: '#4A752E'
};

const info = {
    contrastText: '#ffffff',
    main: '#63656A',
    light: '#939598',
    dark: '#000000'
};

export const warning = {
    contrastText: '#ffffff',
    main: '#F0A055',
    light: '#F6CCA0',
    dark: '#EC7632'
};

export const error = {
    contrastText: '#ffffff',
    main: '#E86A57',
    light: '#EEA796',
    dark: '#E54D3F',
};

const text = {
    primary: '#000000',
    secondary: '#6b778c',
    disabled: 'rgba(55, 65, 81, 0.48)'
};

export const mySherpasThemeOptions: ThemeOptions = {
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: neutral[500],
                    color: '#FFFFFF',
                    fontSize: '12px',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-filledDefault': {
                        backgroundColor: neutral[200],
                        '& .MuiChip-deleteIcon': {
                            color: neutral[400]
                        }
                    },
                    '&.MuiChip-outlinedDefault': {
                        '& .MuiChip-deleteIcon': {
                            color: neutral[300]
                        }
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: text.secondary
                    },
                },
                root: {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${secondary.light} !important`,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${neutral[900]} !important`
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-focusVisible': {
                        outlineColor: neutral[900],
                        outlineWidth: 2,
                        outlineStyle: 'solid',
                        border: 'solid #fff 1px'
                    }
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: divider
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderColor: divider,
                    borderStyle: 'solid',
                    borderWidth: 1
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderColor: divider,
                    borderStyle: 'solid',
                    borderWidth: 1
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: neutral[500]
                },
                track: {
                    backgroundColor: neutral[400],
                    opacity: 1
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${divider} `
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: neutral[100],
                    '.MuiTableCell-root': {
                        color: neutral[700]
                    }
                }
            }
        }
    },
    palette: {
        action: {
            active: neutral[500],
            focus: 'rgba(55, 65, 81, 0.12)',
            hover: 'rgba(55, 65, 81, 0.04)',
            selected: 'rgba(55, 65, 81, 0.08)',
            disabledBackground: 'rgba(55, 65, 81, 0.12)',
            disabled: 'rgba(55, 65, 81, 0.26)'
        },
        background,
        divider,
        error,
        info,
        mode: 'light',
        neutral,
        primary,
        secondary,
        success,
        text,
        warning
    },
    shadows: [
        'none',
        '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
        '0px 1px 2px rgba(100, 116, 139, 0.12)',
        '0px 1px 4px rgba(100, 116, 139, 0.12)',
        '0px 1px 5px rgba(100, 116, 139, 0.12)',
        '0px 1px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 6px rgba(100, 116, 139, 0.12)',
        '0px 3px 6px rgba(100, 116, 139, 0.12)',
        '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
        '0px 5px 12px rgba(100, 116, 139, 0.12)',
        '0px 5px 14px rgba(100, 116, 139, 0.12)',
        '0px 5px 15px rgba(100, 116, 139, 0.12)',
        '0px 6px 15px rgba(100, 116, 139, 0.12)',
        '0px 7px 15px rgba(100, 116, 139, 0.12)',
        '0px 8px 15px rgba(100, 116, 139, 0.12)',
        '0px 9px 15px rgba(100, 116, 139, 0.12)',
        '0px 10px 15px rgba(100, 116, 139, 0.12)',
        '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
        '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
        '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)',
        '0px 25px 50px rgba(100, 116, 139, 0.25)'
    ],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            desktop: 1336,
            xl: 1536,
            xxl: 2560,
        },
    },
};

export const thumbnailStyle = {
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: neutral[300],
    backgroundColor: neutral[200],
    overflow: 'hidden',
}
