import { AppUserChangePasswordRequestDto, SecurityResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const adminApi = baseApi.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
    endpoints: (build) => ({
        adminResetPassword: build.mutation<SecurityResponseDto, AppUserChangePasswordRequestDto>({
            query: (data) => ({
                url: `/v1/auth/adminResetPassword`,
                method: 'POST',
                data,
            })
        }),
    }),
    overrideExisting: true,
})

export const {
    useAdminResetPasswordMutation,
} = adminApi;



