
import { type ActivationState, Client } from '@stomp/stompjs';
import { isDevelopmentMode, isLocalhost, isServer } from 'src/utils';

const appPort = (!isServer && window.location.port) ? ':' + window.location.port : '';
export const WEBSOCKET_SERVER_DEV = !isServer ? `ws://${window.location.hostname}${appPort}/api/websocket` : null;
export const WEBSOCKET_SERVER_PROD = !isServer ? `wss://${window.location.hostname}${appPort}/api/websocket` : null;

interface SocketMessage {
    type: string,
    message: string,
}

export interface ISocket {
    // type constructor type
    connect(): void,
    toggleDebug(state: boolean): void,
    disconnect(): void,
    send(message: SocketMessage): void,
    subscribe: (topic: string, callback: (message: any) => void) => () => void,
}

export class Socket implements ISocket {
    private static instance: Socket;
    // socket instance
    #socket: Client = null
    // debug mode is disabled by default
    #debug = true
    #reconnectDelay: 5000;
    #heartbeatIncoming: 4000;
    #heartbeatOutgoing: 4000;

    constructor(debug = false, onConnectionChangeCallback?: (isConnected: boolean, connection?: Socket) => void) {
        if (!this.#socket) {
            this.#socket = new Client({
                brokerURL: (isDevelopmentMode || isLocalhost) ? WEBSOCKET_SERVER_DEV : WEBSOCKET_SERVER_PROD,
                debug: (message: string) => { },
                onConnect: () => {
                    onConnectionChangeCallback(true, this);
                },
                onDisconnect: () => {
                    onConnectionChangeCallback(false);
                },
                onStompError: () => {
                    onConnectionChangeCallback(false);
                },
                onWebSocketClose: () => {
                    onConnectionChangeCallback(false);
                },
                onWebSocketError: () => {
                    onConnectionChangeCallback(false);
                },
                onChangeState: (state: ActivationState) => {
                    onConnectionChangeCallback(state === 0);
                }
                // reconnectDelay: this.#reconnectDelay,
                // heartbeatIncoming: this.#heartbeatIncoming,
                // heartbeatOutgoing: this.#heartbeatOutgoing,
            });
            this.#socket.activate()
        }
        this.#debug = debug
    }

    // static method to access the instance
    public static getInstance(debug = false, onConnectionChangeCallback?: (isConnected: boolean, connection?: Socket) => void): Socket {
        if (!Socket.instance) {
            Socket.instance = new Socket(debug, onConnectionChangeCallback);
        }
        onConnectionChangeCallback(true, Socket.instance)
        return Socket.instance;
    }

    // connect to websocket
    connect() {
        // this.#socket.activate()
    }
    private debug(message: string) {
        if (this.#debug) {
            console.info(message)
        }
    }
    // turn on/off debug mode
    toggleDebug(state: boolean) {
        this.#debug = state
    }
    // disconnect from websocket
    disconnect() {
        if (this.#socket) {
            this.#socket.deactivate();
            this.#socket = null
        }
    }
    // send message to websocket
    // currently not used
    send(message: any) {
        if (this.#socket) {
            // this.#socket.send(JSON.stringify(message))
        }
    }
    // subscribe to topic and listen for messages
    subscribe(topic: string, callback: (message: any) => void) {
        if (!this.#socket || this.#socket.state !== 0 || !this.#socket.connected) {
            return () => { };
        }
        const { unsubscribe } = this.#socket.subscribe(topic, callback);
        // return unsubscribe function
        return unsubscribe;
    }
}

