import { LoanEntityLabel } from "src/backend";

import { TKnowledgeBase } from "./formelement";

export const EntityLabel = {
    [LoanEntityLabel.PRIMARY_OPERATION_COMPANY]: {
        short: "POC",
        long: "Primary Operating Company",
    },
    [LoanEntityLabel.OPERATING_COMPANY]: {
        short: "OC",
        long: "Operating Company",
    },
    [LoanEntityLabel.EPC]: {
        short: "EPC",
        long: "Eligible Passive Company",
    },
    [LoanEntityLabel.AFFILIATE]: {
        short: "Affiliate",
        long: "Affiliate",
    },
    [LoanEntityLabel.RELATED_NON_AFFILIATE]: {
        short: "RNA",
        long: "Related Non-Affiliate",
    },
    [LoanEntityLabel.NOT_A_GUARANTOR]: {
        short: "NAG",
        long: "Not a Guarantor",
    },
    [LoanEntityLabel.FRANCHISEE]: {
        short: "Franchisee",
        long: "Franchisee",
    },
    [LoanEntityLabel.NEW]: {
        short: "New",
        long: "New",
    },
    [LoanEntityLabel.PRIMARY_COLLATERAL]: {
        short: "Primary",
        long: "Primary",
    },
    [LoanEntityLabel.SPOUSE]: {
        short: "Spouse",
        long: "Spouse",
    },
    [LoanEntityLabel.GUARANTOR]: {
        short: "Guarantor",
        long: "Guarantor",
    },
    [LoanEntityLabel.APPLICANT]: {
        short: "Borrower",
        long: "Borrower",
    },
    [LoanEntityLabel.MEMBER]: {
        short: "Member",
        long: "Member",
    },
    [LoanEntityLabel.SECURED_LIMITED]: {
        short: "Secured Limited",
        long: "Secured Limited",
    },
    [LoanEntityLabel.SECURED_FULL]: {
        short: "Secured Full",
        long: "Secured Full",
    },
    [LoanEntityLabel.UNSECURED_FULL]: {
        short: "Unsecured Full",
        long: "Unsecured Full",
    },
    [LoanEntityLabel.UNSECURED_LIMITED]: {
        short: "Unsecured Limited",
        long: "Unsecured Limited",
    },
    [LoanEntityLabel.NON_AFFILIATE]: {
        short: "Non-Affiliate",
        long: "Non-Affiliate",
    },
}

export const LoanCompanyLabels = [
    LoanEntityLabel.AFFILIATE,
    LoanEntityLabel.EPC,
    LoanEntityLabel.OPERATING_COMPANY,
    LoanEntityLabel.PRIMARY_OPERATION_COMPANY,
    LoanEntityLabel.RELATED_NON_AFFILIATE,
]

export interface LoanEntity {
    id?: string;
    loanId?: string;
    name: string;
    createFromTemplateId: string;
    knowledgeBase?: TKnowledgeBase;
}

export enum GuaranteeType {
    NOT_A_GUARANTOR = LoanEntityLabel.NOT_A_GUARANTOR,
    UNSECURED_FULL = LoanEntityLabel.UNSECURED_FULL,
    SECURED_FULL = LoanEntityLabel.SECURED_FULL,
    SECURED_LIMITED = LoanEntityLabel.SECURED_LIMITED,
    UNSECURED_LIMITED = LoanEntityLabel.UNSECURED_LIMITED,
}